import React from "react";

import { useGate } from "@nestoca/gate-react";
import { Chip } from "@nestoca/ui";
import { TransactionTypeEnum } from "@shared/constants";
import { useTranslation } from "react-i18next";

import styles from "@components/dashboard-table/dashboard-table.module.scss";

export type TransactionTypeCellProps = {
  applicationType: TransactionTypeEnum;
};

export const TransactionTypeCell = ({
  applicationType,
}: TransactionTypeCellProps) => {
  const { t } = useTranslation("dashboard");
  const gate = useGate();
  const role = gate.getRole();
  const isExternalBroker = role === "externalbroker";

  const renameRenewalToTransfer =
    isExternalBroker && applicationType === "RENEWAL";

  return (
    <Chip
      className={styles.chip}
      label={t(
        `table.applicationTypes.${
          renameRenewalToTransfer ? "TRANSFER" : applicationType
        }`
      )}
    />
  );
};

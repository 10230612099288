import { Chip, ChipProps } from "@nestoca/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import styles from "./digital-chip.module.scss";

export type DigitalChipProps = {
  isDigital: boolean;
} & Omit<ChipProps, "variant" | "label">;

export const DigitalChip = ({
  className,
  isDigital,
  ...rest
}: DigitalChipProps) => {
  const { t } = useTranslation("common");

  return (
    <Chip
      className={clsx(styles.chip, className)}
      variant={isDigital ? "default" : "error"}
      label={isDigital ? t("digital") : t("nonDigital")}
      {...rest}
    />
  );
};

import clsx from "clsx";
import Link, { LinkProps } from "next/link";

import styles from "./post-fetch-link.module.scss";

export type PostFetchLinkProps = {
  children: React.ReactNode;
  className?: string;
} & Omit<LinkProps, "prefetch">;

export const PostFetchLink = ({
  children,
  className,
  href,
  ...rest
}: PostFetchLinkProps) => (
  <Link
    className={clsx(styles["post-fetch-link"], className)}
    href={href}
    prefetch={false}
    passHref
    {...rest}
  >
    {children}
  </Link>
);

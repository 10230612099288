import { useGate } from "@nestoca/gate-react";
import { useGetApplications } from "@shared/api/hooks/applications";
import { ACTIVE_APPLICATION_STATE } from "@shared/constants";
import { useTranslation } from "react-i18next";

import { HighlightsCard, Highlight } from "@components/highlights-card";

import styles from "./dashboard-highlights-card.module.scss";

export const DashboardHighlightsCard = () => {
  const { t } = useTranslation("dashboard");

  const gate = useGate();
  const role = gate.getRole();
  const isExternalBroker = role === "externalbroker";

  const { data, isPending } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [{ id: "currentStatus", value: ACTIVE_APPLICATION_STATE }],
    isExternalBroker,
  });

  const totalResults = data?.totalResults || 0;

  return (
    <HighlightsCard className={styles.card}>
      <Highlight
        count={totalResults}
        label={t("dashboard.activeApplications", {
          count: totalResults,
        })}
        loading={isPending}
      />
    </HighlightsCard>
  );
};

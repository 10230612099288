import { useGate } from "@nestoca/gate-react";
import { Flex, useBreakpointValue } from "@nestoca/ui";
import { useRouter } from "next/router";

import { DashboardHighlightsCard } from "@components/dashboard-highlights-card";
import { RateCard } from "@components/rate-card/rate-card";
import { RateCardMobile } from "@components/rate-card/rate-card.mobile";

const ratesLink =
  "https://sites.google.com/nesto.ca/faqsbrokerchannel/english#h.malpkko7p9e7";
const ratesLinkFr =
  "https://sites.google.com/nesto.ca/faqsbrokerchannel/fran%C3%A7ais#h.jyuilkeya795";

export const DashboardHighlightsGroup = () => {
  const { locale } = useRouter();

  const isMobile = useBreakpointValue({ default: true, md: false });
  const gate = useGate();
  const role = gate.getRole();
  const isExternalBroker = role === "externalbroker";

  const ratesLinkByLocale = locale === "fr" ? ratesLinkFr : ratesLink;

  return (
    <Flex gap={3}>
      {(!isMobile || !isExternalBroker) && <DashboardHighlightsCard />}
      {isExternalBroker &&
        (isMobile ? (
          <RateCardMobile ratesLink={ratesLinkByLocale} />
        ) : (
          <RateCard ratesLink={ratesLinkByLocale} />
        ))}
    </Flex>
  );
};

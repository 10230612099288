import React from "react";

import { useGate } from "@nestoca/gate-react";
import { Button, Flex, Typography, useTabsContext } from "@nestoca/ui";
import { useGetApplications } from "@shared/api/hooks/applications";
import {
  ACTIVE_APPLICATION_STATE,
  APPLICATION_STATE_GROUP,
  ApplicationStateGroup,
  CLOSED_APPLICATION_STATE,
  FUNDED_APPLICATION_STATE,
} from "@shared/constants";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { BsSearch } from "react-icons/bs";

import { useTablesContext } from "@hooks";
import { pushToHistory } from "@utils";

import styles from "./dashboard-table.module.scss";

export const EmptyState = () => {
  const router = useRouter();
  const { t } = useTranslation("dashboard");
  const { onSetUrlSearchParamsByTableTab } = useTablesContext();
  const { setSelectedIndex, selectedIndex } = useTabsContext();
  const gate = useGate();
  const isExternalBroker = gate.allowedRole(["externalbroker"]);

  const { data: activeApplications } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [
      {
        id: "currentStatus",
        value: [...ACTIVE_APPLICATION_STATE],
      },
    ],
    isExternalBroker,
  });

  const { data: fundedApplications } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [
      {
        id: "currentStatus",
        value: [...FUNDED_APPLICATION_STATE],
      },
    ],
    isExternalBroker,
  });

  const { data: closedApplications } = useGetApplications({
    pageIndex: 0,
    pageSize: 1,
    filters: [
      {
        id: "currentStatus",
        value: [...CLOSED_APPLICATION_STATE],
      },
    ],
    isExternalBroker,
  });

  const hasActiveApplications =
    activeApplications && activeApplications.totalResults > 0;
  const hasFundedApplications =
    fundedApplications && fundedApplications.totalResults > 0;
  const hasClosedApplications =
    closedApplications && closedApplications.totalResults > 0;

  const hasFilterParams = router.query.columnFilters;

  const onResetFilters = () => {
    let tab: ApplicationStateGroup = APPLICATION_STATE_GROUP.ACTIVE_GROUP;
    switch (selectedIndex) {
      case 0:
        tab = APPLICATION_STATE_GROUP.ACTIVE_GROUP;
        break;
      case 1:
        tab = APPLICATION_STATE_GROUP.FUNDED_GROUP;
        break;
      case 2:
        tab = APPLICATION_STATE_GROUP.CLOSED_GROUP;
        break;
    }

    // tabs state
    onSetUrlSearchParamsByTableTab?.(tab, new URLSearchParams());

    const defaultDynamicParams = {
      tenant: router.query.tenant as string,
    };

    // current route
    pushToHistory(defaultDynamicParams, "", router);
  };

  return (
    <Flex
      direction="column"
      gap={4}
      align="center"
      className={styles["empty-state"]}
    >
      <BsSearch size={"1.5rem"} />
      <Flex direction="column" gap={0} align="center">
        <Typography size={0}>{t("emptyState.content1")}</Typography>
        <Typography size={0}>{t("emptyState.content2")}</Typography>
        {hasFilterParams && (
          <Button variant="link" onClick={onResetFilters}>
            {t("emptyState.clearParams")}
          </Button>
        )}
      </Flex>
      <Flex gap={3}>
        {hasActiveApplications && selectedIndex !== 0 && (
          <Button variant="secondary" onClick={() => setSelectedIndex(0)}>
            {t("emptyState.seeActive")}
          </Button>
        )}
        {hasFundedApplications && selectedIndex !== 1 && (
          <Button variant="secondary" onClick={() => setSelectedIndex(1)}>
            {t("emptyState.seeFunded")}
          </Button>
        )}
        {hasClosedApplications && selectedIndex !== 2 && (
          <Button variant="secondary" onClick={() => setSelectedIndex(2)}>
            {t("emptyState.seeDeclined")}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

import React from "react";

import { useGate } from "@nestoca/gate-react";
import { Chip, Flex } from "@nestoca/ui";
import { ApplicationState } from "@shared/constants";
import { useTranslation } from "react-i18next";

import { mapApplicationStateToStatus } from "@components/application-details/utils";
import styles from "@components/dashboard-table/dashboard-table.module.scss";
import { ApplicationTableItem } from "@components/dashboard-table/types";

export type StatusCellProps = {
  currentStatus: ApplicationState;
  applicationTableItem: ApplicationTableItem;
};

export const StatusCell = ({
  currentStatus,
  applicationTableItem,
}: StatusCellProps) => {
  const { t } = useTranslation("dashboard");
  const gate = useGate();
  const role = gate.getRole();

  const { contactMade } = applicationTableItem;

  const mappedStatus = mapApplicationStateToStatus({
    currentStatus,
    contactMade,
    role,
  });

  if (!mappedStatus) return <>-</>;

  return (
    <Flex justify="end">
      <Chip
        className={styles.chip}
        label={t(`table.currentStatuses.${mappedStatus}`)}
      />
    </Flex>
  );
};

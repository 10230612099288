import { Flex, FlexProps, Skeleton, Typography } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./highlight.module.scss";

export type HighlightProps = {
  count: number;
  label: string;
  loading?: boolean;
} & FlexProps;

export const Highlight = ({
  className,
  count,
  label,
  loading = false,
  ...rest
}: HighlightProps) => {
  return (
    <Flex
      className={clsx(styles.highlight, className)}
      align="center"
      gap={3}
      {...rest}
    >
      {loading ? (
        <Skeleton width={42} height={42} />
      ) : (
        <Typography className={styles.count} size="h3" weight={7}>
          {count}
        </Typography>
      )}
      <Typography className={styles.label} size={0} weight={7}>
        {label}
      </Typography>
    </Flex>
  );
};

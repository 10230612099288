import { Card, CardProps } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./highlights-card.module.scss";

export type HighlightsCardProps = CardProps;

export const HighlightsCard = ({
  children,
  className,
  ...rest
}: HighlightsCardProps) => {
  return (
    <Card className={clsx(styles["highlights-card"], className)} {...rest}>
      {children}
    </Card>
  );
};

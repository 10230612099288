import { useRef } from "react";

import { Box, Flex, Tooltip, Typography } from "@nestoca/ui";
import { HelocApplication } from "@shared/constants";
import { formatCurrency } from "@shared/utils";
import { useTranslation } from "react-i18next";

import styles from "./table-tooltip.module.scss";

type LoanValueToolTipProps = {
  loanValue?: number;
  mortgageAmountPremiumsIncluded?: number;
  heloc?: HelocApplication;
  id: number;
};

export const LoanValueToolTip = ({
  loanValue,
  heloc,
  mortgageAmountPremiumsIncluded,
  id,
}: LoanValueToolTipProps) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation("applications");

  const tooltipRef = useRef<HTMLElement>(null);

  return (
    <Box
      data-tooltip-id={`${id}-${loanValue}-loan-amount-table-tooltip`}
      data-tooltip-offset={16}
      className={styles["table-tooltip"]}
    >
      <Typography size={0} weight={heloc ? 7 : undefined}>
        {formatCurrency(loanValue, locale)}
      </Typography>
      {heloc && (
        <Tooltip
          id={`${id}-${loanValue}-loan-amount-table-tooltip`}
          className={styles["table-tooltip__tooltip"]}
          direction="top"
          float
          place="top-start"
          noArrow
        >
          <Flex
            ref={tooltipRef}
            className={styles["table-tooltip__content"]}
            direction="column"
            gap={2}
          >
            <Flex direction="column">
              <Typography size={0} weight={7}>
                {formatCurrency(mortgageAmountPremiumsIncluded, locale)}
              </Typography>
              <Typography size="00">{t("details.mortgageAmount")}</Typography>
            </Flex>
            <Flex direction="column">
              <Typography size={0} weight={7}>
                {formatCurrency(heloc?.helocAmount, locale)}
              </Typography>
              <Typography size="00">{t("details.helocAmount")}</Typography>
            </Flex>
          </Flex>
        </Tooltip>
      )}
    </Box>
  );
};

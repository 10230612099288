import React from "react";

import { Tooltip, Typography } from "@nestoca/ui";
import { useTranslation } from "react-i18next";

import styles from "@components/dashboard-table/dashboard-table.module.scss";

export const DigitalHeaderCell = () => {
  const { t } = useTranslation("dashboard");

  const tooltipId = "digital-header-tooltip";

  return (
    <>
      <Typography
        data-tooltip-id={tooltipId}
        size={0}
        textColor="var(--color-black)"
      >
        {t("table.header.isDigital")}
      </Typography>
      <Tooltip
        className={styles["digital-header-tooltip"]}
        place="right"
        id={tooltipId}
        subText={t("table.tooltip.isDigital")}
      />
    </>
  );
};

import React from "react";

import { useTranslation } from "react-i18next";

import {
  ApplicationTableItem,
  ApplicationTableItemProductType,
} from "@components/dashboard-table/types";

export type ProductTypeCellProps = {
  productType: ApplicationTableItemProductType;
  applicationTableItem: ApplicationTableItem;
};

export const ProductTypeCell = ({
  productType,
  applicationTableItem,
}: ProductTypeCellProps) => {
  const { t } = useTranslation("dashboard");

  const { term, type } = productType;

  const { heloc } = applicationTableItem;

  const hasProduct = type || term;

  const productTypeLabel = type ? t(`table.productTypes.${type}`) : "";

  const productTermLabel = term ? t(`table.productTerms.${term}`) : "";

  const productLabel = `${productTypeLabel} ${productTermLabel}`.trim();

  if (!hasProduct && !heloc) return <>-</>;

  return (
    <>
      {`${productLabel} ${productLabel && heloc ? `+` : ""} ${
        heloc ? `${t(`table.productTypes.HELOC`)}` : ""
      }`.trim()}
    </>
  );
};

import React from "react";

import { ApplicationTableItem } from "@components/dashboard-table/types";
import { LoanValueToolTip } from "@components/table-tooltip";

export type LoanValueCellProps = {
  loanValue: number | undefined;
  applicationTableItem: ApplicationTableItem;
};

export const LoanValueCell = ({
  loanValue,
  applicationTableItem,
}: LoanValueCellProps) => {
  const { id, heloc, mortgageAmountPremiumsIncluded } = applicationTableItem;

  return (
    <LoanValueToolTip
      id={id}
      heloc={heloc}
      loanValue={loanValue}
      mortgageAmountPremiumsIncluded={mortgageAmountPremiumsIncluded}
    />
  );
};

import React from "react";

import { ApplicationTableItem } from "@components/dashboard-table/types";
import { ApplicantToolTip } from "@components/table-tooltip";

export type NameCellProps = {
  name: string;
  applicationTableItem: ApplicationTableItem;
};

export const NameCell = ({ name, applicationTableItem }: NameCellProps) => {
  const { id, coApplicantsByRole } = applicationTableItem;

  return (
    <ApplicantToolTip
      id={id}
      applicantName={name}
      coApplicantsByRole={coApplicantsByRole}
    />
  );
};

import { Flex, Typography } from "@nestoca/ui";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BsBoxArrowUpRight } from "react-icons/bs";

import { HighlightsCard } from "@components/highlights-card";

import styles from "./rate-card.module.scss";

export const RateCardMobile = ({ ratesLink }: { ratesLink: string }) => {
  const { t } = useTranslation("dashboard");

  return (
    <HighlightsCard className={clsx(styles.card, styles["card--mobile"])}>
      <Link
        href={ratesLink}
        passHref
        target="blank"
        className={styles["card__link--mobile"]}
      >
        <Flex direction="column">
          <Typography size={1} weight={7}>
            {t("rateCard.title")}
          </Typography>
          <Typography size={0}> {t("rateCard.subTitle")}</Typography>
        </Flex>
        <BsBoxArrowUpRight size={20} />
      </Link>
    </HighlightsCard>
  );
};

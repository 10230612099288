import React, { useState } from "react";

import { useGate } from "@nestoca/gate-react";
import {
  TabList,
  Tabs as Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Chip,
  useBreakpointValue,
} from "@nestoca/ui";
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "@shared/ui";
import clsx from "clsx";
import { useRouter } from "next/router";

import { DynamicParams } from "@constants";
import { useTablesContext } from "@hooks";
import { pushToHistory } from "@utils";

import styles from "./table-tabs.module.scss";

export type TableTabsProps = {
  dynamicParams?: DynamicParams;
};

export const TableTabs = ({ dynamicParams }: TableTabsProps) => {
  const [tabIndexSelected, setTabIndexSelected] = useState(0);

  const { tableTabs, tableTabUrlSearchParams } = useTablesContext();

  const router = useRouter();

  const gate = useGate();

  const isExternalBroker = gate.getRole() === "externalbroker";
  const isMobile = useBreakpointValue({ default: true, md: false });
  const isExternalBrokerMobile = isExternalBroker && isMobile;

  const defaultDynamicParams = dynamicParams || {
    tenant: router.query.tenant as string,
  };

  const handleTableTabChange = (tabIndex: number) => {
    const tableTabId = tableTabs[tabIndex].id;

    const urlSearchParams = tableTabUrlSearchParams[tableTabId];

    setTabIndexSelected(tabIndex);

    // If the table tab has urlSearchParams, push the search query string to the url
    if (urlSearchParams) {
      pushToHistory(defaultDynamicParams, urlSearchParams.toString(), router);
    } else {
      /* If the table tab does not have urlSearchParams, push an empty
       string to the url to remove the query params from the previous table tab */
      pushToHistory(defaultDynamicParams, "", router);
    }
  };

  return (
    <Tabs
      className={clsx(styles["table-tabs"], {
        [styles["table-tabs--mobile"]]: isExternalBrokerMobile,
      })}
      index={tabIndexSelected}
      onChange={handleTableTabChange}
    >
      <TabList
        wrapperClassName={clsx({
          [styles["tablist__wrapper--mobile"]]: isExternalBrokerMobile,
        })}
        className={clsx({
          [styles["tablist--mobile"]]: isExternalBrokerMobile,
        })}
      >
        {tableTabs.map(({ index, id, label, applicationsCount }) => (
          <Tab
            key={id}
            className={clsx(styles.tab, {
              [styles["tab--active"]]: index === tabIndexSelected,
              [styles["tab--mobile"]]: isExternalBrokerMobile,
            })}
          >
            {label}
            {applicationsCount !== undefined && (
              <Chip label={`${applicationsCount}`} size="small" />
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanels
        className={clsx(styles.pannels, {
          [styles["pannels--mobile"]]: isExternalBrokerMobile,
        })}
      >
        {tableTabs.map(({ index, table }) => (
          <TabPanel
            key={index}
            className={clsx(styles.panel, {
              [styles["panel--mobile"]]: isExternalBrokerMobile,
            })}
          >
            {isExternalBrokerMobile ? (
              table
            ) : (
              <ScrollAreaRoot className={styles["scroll-area"]}>
                <ScrollAreaViewport className={styles["scroll-area__viewport"]}>
                  {table}
                </ScrollAreaViewport>
                <ScrollAreaScrollbar orientation="horizontal">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
              </ScrollAreaRoot>
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
